import { Box, Button, ButtonGroup, createTheme, Stack, ThemeProvider, Typography } from "@mui/material";

import CutImage from '../../shared/img/3d/cut.png';
import GrainImage from '../../shared/img/3d/grain.png';
import SeamImage from '../../shared/img/3d/seam.png';
import TrimImage from '../../shared/img/3d/trim.png';
import CanadaBeefLogo from '../../shared/img/logo.jpg';
import Wysiwyg from "../Wysiwyg";
import { useState } from "react";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

const theme = createTheme({
  palette: {
    secondary: {
      main: grey[400],
    },
  },
});

export const ThreeDViewer = ({ account = 'd8s-bo1ulh', skuBlank, skuAnnotated }) => {
  const { t } = useTranslation();

  const [unannotated, setUnannotated] = useState((skuBlank) ? true : false);

  window.initDimensions({
    account,
    viewers: ["3D"],
  });

  const legendItems = [
    {
      image: CutImage,
      label: 'Cut',
    },
    {
      image: GrainImage,
      label: 'Grain',
    },
    {
      image: SeamImage,
      label: 'Seam',
    },
    {
      image: TrimImage,
      label: 'Trim',
    },
  ];

  return (
    <Box>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Box
          sx={{}}
        >
          {/* {
            skuBlank && skuAnnotated && (
              <Stack
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 1,
                }}
              >
                <ThemeProvider theme={theme}>
                  <ButtonGroup
                    aria-label="Basic button group"
                    color={'secondary'}
                  >
                    <Button
                      variant={unannotated ? 'contained' : 'outlined'}
                      onClick={() => setUnannotated(true)}
                      sx={{
                        color: '#212121',
                        textTransform: 'none',
                        boxShadow: 'none',
                      }}
                    >
                      Plain View
                    </Button>
                    <Button
                      variant={!unannotated ? 'contained' : 'outlined'}
                      onClick={() => setUnannotated(false)}
                      sx={{
                        color: '#212121',
                        textTransform: 'none',
                        boxShadow: 'none',
                      }}
                    >
                      Annotated View
                    </Button>
                  </ButtonGroup>
                </ThemeProvider>
              </Stack>
            )
          } */}
        </Box>

        <Box
          sx={{
            width: '6rem',
          }}
        >
          <img
            className="w-100"
            src={CanadaBeefLogo}
            alt={'Canada Beef'}
          />
        </Box>
      </Stack>

      <div
        id="three-d-viewer"
        style={{
          height: '450px',
          backgroundColor: '#f6f6f6',
        }}
        data-d8s-type="3d"
        // data-d8s-id={unannotated ? skuBlank : skuAnnotated}>
        data-d8s-id={skuBlank}>
      </div>

      <Typography
        component={'div'}
        sx={{
          mt: 0.5,
          color: '#6a6868',
        }}
      >
        { t('threedDisclaimer') }
      </Typography>

      {
        unannotated === false && (
          <Box
            sx={{
              mt: 3,
            }}
          >
            <Stack
              direction={'row'}
              spacing={0}
              justifyContent={'center'}
              sx={{
              }}
            >
              {
                legendItems.map(item => (
                  <Box
                    key={item.label}
                    sx={{
                      border: '1px solid #e0e0e0',
                    }}
                  >
                    <Stack
                      direction={'column'}
                      alignItems={'center'}
                    >
                      <Box>
                        <img
                          className='quality-guide-image'
                          src={item.image}
                          alt={'Trim'}
                          style={{ width: "5rem" }}
                        />
                      </Box>

                      <Typography
                        sx={{
                          fontWeight: 'bold',
                        }}
                        component={'div'}
                      >
                        <Wysiwyg>
                          {item.label}
                        </Wysiwyg>
                      </Typography>
                    </Stack>
                  </Box>
                ))
              }
            </Stack>
          </Box>
        )
      }
    </Box>
  )
}

export default ThreeDViewer